















































import { Vue, Component } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import OrderService from '@/services/OrderService'
import { namespace } from 'vuex-class'
const ModeCartMerge = namespace('ModeCartMerge')

@Component({
  components: {
    LayoutCompact
  }
})
export default class OrderMergeSelect extends Vue {
  public orderMergeAbleList: any = []
  public msg: string = ''

  private orderMergeSelect: any = ''
  @ModeCartMerge.Action
  private SET_NEW_MODE_CART_MERGE!: (data: any) => any

  created() {
    this.getOrderMergeAble()
  }

  private getOrderMergeAble() {
    let tour_mode = localStorage.getItem('tour_mode') ? true : false
    if (!tour_mode) {
      OrderService.getOrderMergeAble()
        .then((res: any) => {
          if (res.data.items.length > 0) {
            this.orderMergeAbleList = res.data.items
          }
        })
        .catch((err: any) => {
          if (err.response.status === 429) {
            this.manyRequest()
          }
          if (err.response.status === 429) {
            this.$router.push({ name: 'login' })
          }
          if (err.response.status === 500) {
            throw 'Internal server error'
          }
        })
    }
  }

  public goToMergeOrder() {
    this.msg = ''
    if (this.orderMergeSelect) {
      localStorage.removeItem('delivery_service_id_normal_edit')
      localStorage.removeItem('delivery_date_normal_edit')
      localStorage.removeItem('order_id_edit')
      this.$router.push({
        name: 'order-merge',
        params: { order_id: this.orderMergeSelect }
      })
      this.SET_NEW_MODE_CART_MERGE(true)
    } else {
      this.msg = 'まとめたい注文IDを選択してください'
    }
  }

  public goToCartNormal() {
    this.$router.push({ name: 'cart' })
  }

  private manyRequest() {
    localStorage.clear()
    this.$bvModal.show(`too-many-request`)
  }
}
